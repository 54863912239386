import { Byline } from "./Byline";
import { OpinionByline } from "./OpinionByline";

import type { Layout } from "vg";

type Article = import("@vgno/article").Article;

type Props = {
  alignment?: string;
  authors: Article["authors"];
  dateline?: string;
  layout?: Layout;
  opinion?: boolean;
  type: string;
};

const Component = ({
  alignment,
  authors,
  dateline,
  layout,
  opinion,
  type,
}: Props) => {
  switch (true) {
    case authors.length === 0:
      return null;

    case opinion:
      return (
        <OpinionByline author={authors[0]} type={type} dateline={dateline} />
      );

    default:
      return (
        <Byline
          alignment={alignment}
          authors={authors}
          dateline={dateline}
          layout={layout}
          type={type}
        />
      );
  }
};

export default Component;
