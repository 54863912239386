import { Button } from "@vgno/core";
import { ChevronDown, Mail } from "@vgno/icons";
import { classnames } from "@vgno/utils";

import { Dateline } from "./Dateline";

import type { Author } from "vg";

import styles from "./OpinionByline.module.css";

type Article = import("@vgno/article").Article;

interface Props {
  author: Author;
  type: Article["type"];
  dateline?: string;
}

export const OpinionByline = ({ author, type, dateline }: Props) => {
  const url = import.meta.env.DEV ? "https://local.vg.no:3443" : "";

  const image = author?.imageAsset;
  const srcSet = image?.urls
    .map(({ url, width }) => `${url} ${width}w`)
    .join(", ");

  const email = author.contacts.find(
    (contact) => contact.type === "email",
  )?.value;

  return (
    <div
      className={classnames(
        "label-medium label-primary font-inter",
        styles.byline,
        "layout-component",
        "layout-normal",
        "layout-padded",
      )}
    >
      <a
        className={classnames(styles.link)}
        href={`${url}/forfatter/${author.slug}`}
      >
        <span
          className={classnames(
            `font-weight-${type === "feature" ? "regular" : "semi-bold"}`,
            styles.name,
          )}
        >
          {author.title}
        </span>
        <span className={classnames("label-secondary", styles.title)}>
          {author.contacts.find((contact) => contact.type === "title")?.value}
          {dateline && (
            <Dateline dateline={dateline} type={type} bold={false} />
          )}
        </span>
        {image && (
          <img
            alt={`Bilde av ${author.title}`}
            className={styles.image}
            height={image.size.height}
            itemProp="image"
            sizes="(max-width: 580px) 112px, 200px"
            src={image.urls[0].url}
            srcSet={srcSet}
            width={image.size.width}
          />
        )}
      </a>
      <div className={classnames(styles.info)}>
        {author.description && (
          <Button
            className={styles.button}
            onClick={() =>
              document
                .querySelector(`.${styles.byline}`)
                ?.classList.toggle(styles.expanded)
            }
            shape="capsule"
            style={type === "feature" ? "bordered" : "gray"}
          >
            <span>Info</span>
            <ChevronDown className={styles.icon} />
          </Button>
        )}
        {email && (
          <Button
            className={styles.button}
            href={`mailto:${email}`}
            shape="capsule"
            style={type === "feature" ? "bordered" : "gray"}
          >
            <Mail />
          </Button>
        )}
        <hr className={styles.border} />
        {author.description && (
          <p className={classnames(styles.description, styles.hidden)}>
            {author.description}
          </p>
        )}
      </div>
    </div>
  );
};
