import { Local } from "@vgno/icons";
import { classnames } from "@vgno/utils";

import styles from "./Byline.module.css";

export const Dateline = ({
  dateline,
  type,
  bold = true,
}: {
  dateline: string;
  type?: string;
  bold?: boolean;
}) => {
  return (
    <span
      className={classnames(
        bold && "font-weight-semi-bold",
        "label-secondary",
        styles.dateline,
      )}
    >
      {type !== "feature" && <Local aria-label="Plasseringsnålikon" />}
      {dateline}
    </span>
  );
};
